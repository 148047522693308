<button class="round_button" (click)="toggleOverlay()" style="margin-right:5px;" xmlns="http://www.w3.org/1999/html">
    <img src="../design/icons/calendar.png" style="margin-top:1px; height:24px; margin-left: 0px;">
</button>

<div class="shadow"
     id="tooltip{{overlayId}}"
     style="overflow: hidden;
     width:fit-content;
     height:0;
     visibility:hidden;
     position: absolute;
     z-index: 555;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    border: 1px solid #ceced2 !important;
    background-color: #ffffff !important;
    color: #000 !important;
     margin-top: 23px; white-space: pre;">
    <div style="width:100%; height:100%; margin-left: 10px;" [innerHTML]="toolTipContent" class="tooltipContent"></div>
</div>

<div id="{{overlayId}}" class="shadow" style="overflow: auto; visibility:hidden; color: #000000; position: fixed; z-index: 555; background-color: #ffffff; border: 1px solid #999999;margin-top: 24px; width:350px; max-height: calc( 60% + 15px );">
    <DIV class="calendar" style="z-index:9999999;display: inline-block; vertical-align: middle; margin-bottom: 1px; margin-right: -1px;" id="{{overlayId}}CalendarPanel" *ngIf="OverlayActive">
        <DIV class="calendarOverlayOpen">
            <DIV *ngIf="viewDateForm" class="form" style="position: fixed;width:100%; height: 100%; z-index: 999999999;    background-color: rgb(73, 126, 220)">
                <DIV class="form" style="position: fixed; vertical-align: top;top:10px;bottom:10px;left:10px;right:calc( 50% + 5px);border: solid 1px black; border-radius: 7px 7px 7px 7px; -moz-border-radius: 7px 7px 7px 7px; -webkit-border-radius: 7px 7px 7px 7px; background: white;">
                   <div style="height:32px; background: rgb(52, 84, 174); width:100%; font-size: 14px; line-height: 1; color:white;">
                       <span style="display: inline-block; margin-top: 8px; margin-left: 10px; height: 20px;">
                           <span *ngIf="formData.id==''"><img style="vertical-align: sub;" src="../design/icons/calendar_add.png"> Neuen Termin <span *ngIf="formData.tid>0">zu Vorgang <strong>{{formData.tid}} </strong></span>erstellen</span>
                           <span *ngIf="formData.id!=''"><img style="vertical-align: sub;" src="../design/icons/calendar_edit.png"> Termin  <span *ngIf="formData.tid>0">zu Vorgang <strong>{{formData.tid}} </strong></span>bearbeiten</span>
                       </span>
                   </div>
                    <table cellpadding="4" cellspacing="0" class="form" style="height:calc( 100% - 52px); margin-left: 15px;margin-top:4px; width: calc( 100% - 30px);">
                        <tr>
                            <td style="height:45px; width: 50%" colspan="2">
                                <span class="formCaption">Titel</span>
                                <input [(ngModel)]="formData.name"
                                       style="width: calc( 100% - 7px );"
                                       tabindex="220"
                                       type="text">
                            </td>
                            <td style="height:45px; width: 25%">
                                <span *ngIf="false">
                                <span class="formCaption">Wiederholen</span>

                                <select *ngIf="formData.recurringMode=='0'" (change)="changeRecurringMode()" style="width:100%;" [(ngModel)]="formData.recurringMode">
                                    <option value="0">Nicht wiederholen</option>
                                    <option value="1">Täglich</option>
                                    <option value="2">Wöchentlich</option>
                                    <option value="3">Monatlich</option>
                                    <option value="4">Jährlich</option>
                                    <option value="5">Benutzerdefiniert</option>
                                </select>
                                <select *ngIf="formData.recurringMode!='0'" (change)="resetRecurringMode()" style="width:100%;" [(ngModel)]="formData.recurringChange">
                                    <option value="0">Nicht wiederholen</option>
                                    <option value="1">{{formData.recurringName}}</option>
                                    <option value="2">Ändern</option>
                                </select>
                                    </span>
                            </td>
                            <td style="height:45px; width: 25%">
                                <span class="formCaption">Erstellen im</span>
                                <select style="width:100%;" [(ngModel)]="formData.fk_user_owner">
                                    <ng-container *ngFor="let calendar of calendarGranted" >
                                        <option *ngIf="calendar.rights==2" [value]="calendar.fk_user_owner">{{calendar.name}}</option>
                                    </ng-container>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:45px;">
                                <span class="formCaption">Beginn</span><br>
                                <span app-date-picker
                                      [(model)]="formData.startDate"
                                      [(boundDatepicker)]="formData.endDate"
                                      id="dateFrom"
                                      tabindex="102"
                                      first="true"
                                      [markOnSet]="false">
                                    [markOnSet]="true"></span>
                                <time-picker [(ngModel)]="formData.timeStart"
                                             (change)="timeStartChange()"></time-picker>
                            </td>
                            <td style="height:45px;">
                                <span class="formCaption">Ende:</span><br>
                                <span app-date-picker
                                      [(model)]="formData.endDate"
                                      [(boundDatepicker)]="formData.startDate"
                                      id="dateTo"
                                      tabindex="102"
                                      first="true"
                                      [markOnSet]="false"></span>
                                <time-picker [(ngModel)]="formData.timeEnd"></time-picker>
                            </td>
                            <td style="height:45px;">
                            <label><input type="checkbox" [(ngModel)]="formData.full_day"> Termin ist ganztägig</label>
                            </td>
                            <td style="height:45px;">
                                <label *ngIf="formData.tid>0"><input type="checkbox" [(ngModel)]="formData.hide_process"> Vorgang Verbergen</label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height:45px;" class="form">
                                <span class="formCaption">Erinnerungen:</span><br>
                                <select (change)="formAddReminder()" [(ngModel)]="formData.newReminder" style="margin-bottom: 4px; width:176px;">
                                    <option value="0">Erinnerung hinzufügen</option>
                                    <option *ngFor="let x of dataReminderSelectable" value="{{x.id}}">{{x.name}}</option>
                                </select>
                                <div style="width:168px;margin-right:4px;
                                    font-weight: revert;
                                    border-radius: 3px !important;
                                    border: 1px solid #c5c5c5;
                                    font-size: 11px;
                                    background-color: #e1e1e1;
                                    color: #000;
                                    height: 22px;
                                    padding-left: 6px;
                                    line-height: 2;
                                    display: inline-block;
                                    margin-bottom: 4px;
                                    vertical-align: top;" *ngFor="let x of formData.reminderArray">
                                    {{x.name}}
                                    <img (click)="removeReminder(x);" src="../design/icons/delete.png" style="margin-top:3px;float: right;" tooltip="Erinnerung entfernen" >
                                    <button  [ngClass]="{'smsButton' : true, 'smsButtonSelected' : x.sms == 1}"  (click)="x.sms = !x.sms;" tooltip="Auch per SMS Erinnern.">SMS</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <editor [(ngModel)]="formData.text"
                                        [init]="settings.tinyMceInit"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        class="noForm"
                                        initialValue=""
                                        style="height:100%;">
                                </editor>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="height: 30px;" class="form">
                                <button (click)="saveDate()" style="float: right;"><img src="../design/icons/disk.png"> Speichern</button>
                                <button (click)="closeDateEditForm()" style="float: right; margin-right: 4px;"><img src="../design/icons/cross.png"> Abbrechen</button>
                            </td>
                        </tr>
                    </table>

                </DIV>
                <DIV class="form" style="position: fixed; vertical-align: top;top:10px;bottom:10px;left:calc( 50% + 5px);right:10px;border: solid 1px black; border-radius: 7px 7px 7px 7px; -moz-border-radius: 7px 7px 7px 7px; -webkit-border-radius: 7px 7px 7px 7px; background: white; overflow: auto;">
                    <app-process-progress *ngIf="formData.tid>0" [loadTid]="formData.tid"></app-process-progress>
                </DIV>
            </DIV>

            <div style="position: absolute;left:10px;top:10px;bottom:10px;width:220px;">
                    <div id="leftContainerDefinition" class="headerNone footerNone">
                        <div id="leftContainerContent" style="border-radius: 7px 7px 7px 7px; -moz-border-radius: 7px 7px 7px 7px; -webkit-border-radius: 7px 7px 7px 7px; overflow: hidden;">
                            <div style="height:230px;">
                                <div *ngIf="sideCalendar1!=undefined" style="display: block; width:calc(100% - 8px ); Heigh:24px; font-size: 10px; padding: 4px">
                                    {{sideCalendar1.getRangeAreaText()}}
                                    <button style="float: right;margin-left: 4px; margin-top: -1px; max-width:16px;height:16px;display: inline-block;line-height: 1; padding: 0;" (click)="moveSideCalendar(1)">&#11166;</button>
                                    <button style="float: right;margin-left: 4px; margin-top: -1px; max-width:16px;height:16px;display: inline-block;line-height: 1; padding: 0;" (click)="moveSideCalendar(-1)">&#11164;</button>
                                </div>
                                <div style="width:100%;">
                                    <app-calendar-view  #sideCalendar1
                                                        name="sideCalendar1"
                                                        (select)="changeMainCalendarTo($event)"
                                                        [viewMode]="11"
                                                        [small]="true"
                                                        (drop)="dropDateOnSideCalendar($event)"
                                                        [followRange]="0">
                                    </app-calendar-view>
                                </div>
                            </div>
                            <div style="height:230px;">
                                <div *ngIf="sideCalendar2!=undefined" style="display: block; width:100%; Heigh:24px; font-size: 10px;padding: 4px">{{sideCalendar2.getRangeAreaText()}}</div>
                                <div style="width:100%;">
                                    <app-calendar-view  #sideCalendar2
                                                        name="sideCalendar2"
                                                        (select)="changeMainCalendarTo($event)"
                                                        [viewMode]="11"
                                                        [small]="true"
                                                        (drop)="dropDateOnSideCalendar($event)"
                                                        [followRange]="1">
                                    </app-calendar-view>
                                </div>
                            </div>
                            <div style="width:100%; height:calc(100% - 690px); border-top: 1px solid gray;">
                                <div style="height:28px; background: lightgrey;border-bottom: 1px solid gray;">
                                    <div style="padding: 5px 1px 5px 5px;" class="form" >Kalender

                                        <button *ngIf="false" style="float: right; margin-top: -1px; margin-right: 1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="changeViewAllCalendarGranted()" tooltip="Alle Kalender anzeigen">
                                            <img *ngIf="viewAllCalendarGranted" src="../design/icons/select_all.png" style="margin-left:-2px;">
                                            <img *ngIf="!viewAllCalendarGranted" src="../design/icons/select_none.png" style="margin-left:-2px;">
                                        </button>
                                        <button style="float: right; margin-top: -1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="showCalendarSettings()" tooltip="Einstellungen">
                                            <img src="../design/icons/cog.png" style="margin-left:-2px;">
                                        </button>
                                    </div>
                                </div>
                                <div class="grantedList" style="height:calc( 100% - 29px );">
                                    <div *ngFor="let calendar of this.calendarGranted"
                                         style="padding: 5px 1px 5px 5px; border-bottom: 1px solid lightgrey;"
                                         class="form date {{'color'+calendar.color}}">

                                        {{calendar.name}}
                                        <button style="float: right; margin-top: -1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="changCalendarGrantedVisibility(calendar)" tooltip="Kalender ein oder ausblenden">
                                            <img *ngIf="calendar.visible==1" src="../design/icons/eye.png" style="margin-left:-2px;">
                                            <img *ngIf="calendar.visible==0" src="../design/icons/eye_inactive.png" style="margin-left:-2px;">
                                        </button>
                                        <button style="float: right; margin-top: -1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="setCalendarGrantedToSolo(calendar)" tooltip="Nur dieser Kalender angezeigt">
                                            <img *ngIf="calendar.solo!=1" src="../design/icons/unmarked.png" style="margin-left:-2px;">
                                            <img *ngIf="calendar.solo==1" src="../design/icons/marked.png" style="margin-left:-2px;">
                                        </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div style="position: absolute;right:10px;top:10px;bottom:10px;width:calc( 100% - 250px);">
                    <div id="rightContainerDefinition" class="headerNone footerNone" >

                        <div id="rightContainerContent" style="-webkit-border-radius: 7px 7px 7px 7px; overflow: hidden;">
                            <div style="position: relative;top:0; left:0; right:0; height:30px; ">

                                <table style="width:100%;margin-top: 1px;">
                                    <tr style="height:24px;">
                                        <td colspan="7" style="text-align: center;">
                                            <div style="float: left;margin-top:2px; display: inline-block;">
                                                <button style="float: left;min-width:24px; height:20px;margin-left:6px;" (click)="this.mainCalendar.viewToday()">Heute</button>
                                                <select style="float: left;min-width:24px; height:20px;margin-left:6px; font-size:11px;" (change)="this.mainCalendar.changeKw()" [(ngModel)]="this.mainCalendar.weekOfYear">
                                                    <option *ngFor="let item of [].constructor(52); let kw = index;" value="{{kw+1}}">KW:{{kw+1}}</option>
                                                </select>
                                                <button style="float: left;margin-left: 4px; max-width:20px;height:20px;line-height:1;padding:2px;display: inline-block;" (click)="this.mainCalendar.changeViewArea(-1)">&#11164;</button>
                                                <button style="float: left;margin-left: 4px; max-width:20px;height:20px;line-height:1;padding:2px;display: inline-block;" (click)="this.mainCalendar.changeViewArea(1)">&#11166;</button>
                                                <div style="float:left; display: inline-block; margin-left: 10px;margin-top: 1px;font-size: 0.8em; line-height: 1.6;" *ngIf="mainCalendar != undefined">{{mainCalendar.getRangeAreaText()}}</div>
                                                 --- {{this.max_last_changed}}
                                            </div>
                                            <div *ngIf="processDataForNewDate" style="padding:7px; margin-top: -4px !important; display: inline-block;margin: auto 0;text-align: center; " class="error">
                                                Termin für den Vorgang <strong>{{processDataForNewDate.tid}}</strong> erstellen.
                                            </div>
                                            <div style="float:right; display: inline-block;margin-right:2px;" class="form">
                                                <button *ngIf="this.mainCalendar.viewMode>0 && this.mainCalendar.viewMode<11" style="min-width:24px; height:24px;" (click)="this.mainCalendar.changeDayCellHeight('+')">+</button>
                                                <button *ngIf="this.mainCalendar.viewMode>0 && this.mainCalendar.viewMode<11" style="min-width:24px; height:24px;" (click)="this.mainCalendar.changeDayCellHeight('-')">-</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(0)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 0}">Terminübersicht</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(1)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 1}">Tag</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(5)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 5}">5 Tage</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(7)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 7}">Woche</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(10)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 10}">10 Tage</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(11)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 11}">Monat</button>
                                                <button style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(12)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 12}">Jahr</button>
                                                <button *ngIf="false" style="min-width:24px; height:22px;" (click)="this.mainCalendar.changeViewMode(13)" [ngClass]="{'marked' : mainCalendar != undefined && mainCalendar.getViewMode() == 13}">Zeitleiste</button>
                                                <button style="min-width:24px; height:22px;" (click)="toggleOverlay()"><img src="../design/icons/cross.png"></button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div style="height: calc( 100% - 30px );width: calc( 100% );border-top: 1px solid #b4b4b4; padding: 0; " cdkDropListGroup>
                                <div style="width:70%; height: 100%; display:inline-block;">
                                    <app-calendar-view #mainCalendar
                                                       name="mainCalendar"
                                                       [viewMode]="7"
                                                       [(ngModel)]="filteredData"
                                                       (edit)="editDate($event)"
                                                       (new)="newDate($event)"
                                                       (view)="viewDate($event)"
                                                       (process)="viewProcess($event)"
                                                       (save)="saveDateTime($event)"
                                                       (delete)="checkDeleteDate($event)"
                                                       (changeTimeRange)="timeRangeSelectionChanged($event)"
                                                       [granted]="this.calendarGranted"
                                                       [timeBoxData]="this.timeBoxData">
                                    </app-calendar-view>
                                </div>
                                <div style="width:30%; height: 100%; float: right;  display:inline-block; overflow: auto;">
                                    <table class="data_tabel light" style="width: 100%;" cellspacing="0" cellpadding="4">
                                        <tr>
                                            <th>Zur Bearbeitung markierte Vorgänge</th>
                                        </tr>
                                        <tr *ngFor="let x of markedProcessData">
                                            <td>
                                                <div cdkDropList>
                                                    <div cdkDrag class="dragDiv" [cdkDragData]="x" style="background: white;">
                                                        <div>
                                                            <span *ngIf="x.typ==1 && x.project=='0'"><b>Vorgang:</b></span>
                                                            <span *ngIf="x.typ==28 && x.project=='0'"><b>Ausgegangene Mail:</b> {{x.sender_user}}</span>
                                                            <span *ngIf="x.typ==29 && x.project=='0'"><b>Eingegangene Mail:</b> {{x.sender_user}}</span>
                                                            <span *ngIf="x.project!='0'"><b>Projekt:</b> {{x.project_name}} - {{x.create_user}}</span>
                                                        </div>
                                                        <div>{{x.titel}}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </DIV>
    </DIV>
</div>
<jw-modal class="jw-modal shadow" id="deleteDate">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div class="form" style="margin:20px; line-height: 1.4;">
        Wollen Sie den Termin wirklich löschen?<br>
        <span style="color:purple; font-weight: bold;">TODO > Anzeige für Gruppentermin anpassen basierend auf this.dateToDelete </span>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="deleteDate();" style="margin-top: 3px;margin-left: 3px; float: right;">
            Löschen
        </button>
        <button (click)="cancelDeleteDate();" style="margin-top: 3px;margin-left: 3px; float: right;">
            Abbrechen
        </button>
    </div>
</jw-modal>

<jw-modal id="editorWarningCalendar" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarningCalendar');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="editorRecurring" class="jw-modal shadow">
    <div class="modalHeader" style="width:308px;">
        <div>
            <img src="../design/icons/arrow_refresh.png" style="vertical-align: sub;"> Termin wiederholen
        </div>
    </div>
    <div *ngIf="formData">
        <div style="margin:24px;" class="form">
            Erster Termin am
            <span app-date-picker
                  [(model)]="formData.startDate"
                  tabindex="102" >
            </span><BR><BR>

            Wiederholen alle
            <input (change)="generateRecurringText()" type="number" name="tentacles" min="1" max="365" style="width:36px;margin-left: 5px;" [(ngModel)]="formData.recurringCount" />
            <select (change)="changeRecurringMode();" style="width:100%;" [(ngModel)]="formData.recurringMode" style="width:78px; margin-left:4px;">
                <option value="1">Tag</option>
                <option value="2">Woche</option>
                <option value="3">Monat</option>
                <option value="4">Jahr</option>
            </select>

            <div *ngIf="formData.recurringMode==2" style="margin-top: 22px; margin-bottom: 22px;" >
                Wiederholen am<BR>
                <div style="margin-top:4px;" class="form">s
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('mo')"  [ngClass]="{'active' : formData.recurringWeekday['mo'] }" >Mo</button>
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('di')"  [ngClass]="{'active' : formData.recurringWeekday['di'] }" >Di</button>
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('mi')"  [ngClass]="{'active' : formData.recurringWeekday['mi'] }" >Mi</button>
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('do')"  [ngClass]="{'active' : formData.recurringWeekday['do'] }" >Do</button>
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('fr')"  [ngClass]="{'active' : formData.recurringWeekday['fr'] }" >Fr</button>
                    <button style="min-width:30px;min-height:30px;margin-right:8px;" (click)="swapRecurringWeekday('sa')"  [ngClass]="{'active' : formData.recurringWeekday['sa'] }" >Sa</button>
                    <button style="min-width:30px;min-height:30px;" (click)="swapRecurringWeekday('so')"  [ngClass]="{'active' : formData.recurringWeekday['so'] }" >So</button>
                </div>
            </div>

            <div *ngIf="formData.recurringMode>=3 "  style="margin-top: 22px; margin-bottom: 22px;" class="form">
                <select style="width: 256px;" (change)="generateRecurringText()">
                    <option *ngFor="let x of dropdownOptionsMonthOrYear" [value]="x.id">{{x.name}}</option>
                </select>
            </div>

            <div style="margin-top: 14px;" class="form">
                Ende<BR>
                <label style="height:30px;display: inline-block; margin-top: 4px;">
                    <input (change)="generateRecurringText()" type="radio" name="recurringEnd" value="JavaScript" checked>Nie
                </label><BR>
                <label style="height:30px;display: inline-block;">
                    <input (change)="generateRecurringText()" type="radio" name="recurringEnd" value="JavaScript">Am
                    <span app-date-picker
                          (change)="generateRecurringText()"
                          style="margin-left: 12px;"
                          [(model)]="formData.recurringDateTo"
                          tabindex="102" >
                    </span>
                </label><BR>
                <label style="height:30px;display: inline-block;" *ngIf="false">
                    <input (change)="generateRecurringText()" type="radio" name="recurringEnd" value="JavaScript">
                    Nach <input type="number" name="tentacles" min="1" max="365" style="width:40px;" [(ngModel)]="formData.recurringCount" /> Terminen
                </label><BR>
            </div>
            <div style="margin-top: 10px; width:257px;">
                {{formData.recurringText}}
            </div>
        </div>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorRecurring');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal class="jw-modal shadow" id="calenderSettings">
    <div class="modalHeader" style="border-bottom: 1px solid #777676;">
        <div style="margin: 0;">
            <div style="margin-left:10px; margin-top:2px;">
                <img src="../design/icons/group_gear.png" style="vertical-align: -webkit-baseline-middle; margin-right:10px; ">
                <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedSettingsTab === 1 }" style="margin-right:5px;" (click)="changeSettingsTab(1)">Von mir Freigegeben</button>
                <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedSettingsTab === 2 }" style="margin-right:5px;" (click)="changeSettingsTab(2)">Für mich Freigegeben</button>
                <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedSettingsTab === 3 }" style="margin-right:5px;" (click)="changeSettingsTab(3)">Meine Zeitfenster</button>
            </div>
        </div>
    </div>

    <div *ngIf="currentSelectedSettingsTab==1" style="height:450px; width:450px; ">
        <div style="height:30px; border-bottom: 1px solid gray;"  class="form">
            <select style="width:185px; margin-top: 4px; height: 22px; margin-left: 3px;" [(ngModel)]="newCalenderToRelease.id">
                <option value="-1">Kalender Freigeben für</option>
                <option *ngFor="let calendar of calenderNotReleased" [value]="calendar.id">{{calendar.name}}</option>
            </select>
            <select style="width:143px; margin-top: 4px; height: 22px; margin-left: 3px;" [(ngModel)]="newCalenderToRelease.right">
                <option value="1">Nur Lesen</option>
                <option value="2">Lesen und Schreiben</option>
            </select>
            <button style="margin-right:3px; margin-top:4px;float: right; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="addToCalendarReleased()" >
                <img src="../design/icons/add.png" style="margin-left:-2px; vertical-align: sub;">
            </button>
        </div>
        <div class="grantedList" style="height: 200px;">
            <div *ngFor="let calendar of this.calendarReleased"
                 style="padding: 5px 1px 5px 5px; border-bottom: 1px solid lightgrey;"
                 class="form date {{'color'+calendar.color}}">
                <img *ngIf="calendar.rights=='1'" src="../design/icons/eye.png"     style="vertical-align:sub;margin-left:2px; margin-right: 2px;" tooltip="Nur Lesen">
                <img *ngIf="calendar.rights=='2'" src="../design/icons/pencil.png"  style="vertical-align:sub;margin-left:2px; margin-right: 2px;" tooltip="Lesen und Schreiben">
                {{calendar.name}}

                <button style="float: right; margin-top: -1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="deleteReleasedCalendar(calendar)" tooltip="Nur dieser Kalender angezeigt">
                    <img src="../design/icons/delete.png" style="margin-left:-2px;">

                </button>
            </div>
        </div>
    </div>

    <div *ngIf="currentSelectedSettingsTab==2" style="height:450px; width:450px; ">
        <div class="grantedList" style="height: 230px;">
            <div *ngFor="let calendar of this.calendarGranted"
                 style="padding: 5px 1px 5px 5px; border-bottom: 1px solid lightgrey;"
                 class="form date {{'color'+calendar.color}}">
                <img *ngIf="calendar.rights=='1'" src="../design/icons/eye.png"     style="vertical-align:sub;margin-left:2px; margin-right: 2px;" tooltip="Nur Lesen">
                <img *ngIf="calendar.rights=='2'" src="../design/icons/pencil.png"  style="vertical-align:sub;margin-left:2px; margin-right: 2px;" tooltip="Lesen und Schreiben">
                {{calendar.name}}

                <button style="float: right; margin-top: -1px; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="deleteGrantedCalendar(calendar)" tooltip="Nur dieser Kalender angezeigt">
                    <img src="../design/icons/delete.png" style="margin-left:-2px;">
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="currentSelectedSettingsTab==3" style="height:450px; width:450px; ">
        <div style="height:30px; border-bottom: 1px solid gray;"  class="form">
            <select style="width:95px; margin-top: 4px; height: 22px;margin-right: 4px;" [(ngModel)]="newTimebox.day">
                <option value="1">Montag</option>
                <option value="2">Dienstag</option>
                <option value="3">Mittwoch</option>
                <option value="4">Donnerstag</option>
                <option value="5">Freitag</option>
                <option value="6">Samstag</option>
                <option value="7">Sonntag</option>
            </select>

            <time-picker [(ngModel)]="newTimebox.start_time" style="margin-right: -10px;"></time-picker>
            <time-picker [(ngModel)]="newTimebox.end_time" style="margin-right: -10px;"></time-picker>

            <input type="text"  style="width:190px; margin-top: 4px; height: 18px; margin-right: 4px; margin-left: 4px;" [(ngModel)]="newTimebox.text">

            <button style="margin-right:3px; margin-top:4px;float: right; width:22px;min-width:22px;height:22px !important;display: inline-block;" (click)="addTimebox()" >
                <img src="../design/icons/add.png" style="margin-left:-2px; vertical-align: sub;">
            </button>
        </div>
        <div class="grantedList" style="height:calc( 100% - 29px );">
                <table style="border-collapse: collapse; border:1px;width:100%;">
                    <tr *ngFor="let timebox of this.timeBoxData"  style="padding: 5px 1px 5px 5px; border-bottom: 1px solid lightgrey; font-size: 12px;">
                        <td style="width: 85px;padding: 4px;">
                            <div style="margin-left: 4px;">
                                <span *ngIf="timebox.day==1">Montag</span>
                                <span *ngIf="timebox.day==2">Dienstag</span>
                                <span *ngIf="timebox.day==3">Mittwoch</span>
                                <span *ngIf="timebox.day==4">Donnerstag</span>
                                <span *ngIf="timebox.day==5">Freitag</span>
                                <span *ngIf="timebox.day==6">Samstag</span>
                                <span *ngIf="timebox.day==7">Sonntag</span>
                            </div>
                        </td>
                        <td style="text-align: center;width: 50px;padding: 4px;">
                            {{timebox.start_time}}
                        </td>
                        <td style="text-align: center;width: 50px;padding: 4px;">
                            {{timebox.end_time}}
                        </td>
                        <td style="padding: 2px;">
                            {{timebox.text}}
                        </td>
                        <td style="width: 24px;padding: 4px;">
                            <button style="margin-top: -1px; width:22px; min-width:22px;height:22px !important;display: inline-block;" (click)="deleteTimebox(timebox)" tooltip="Nur dieser Kalender angezeigt">
                                <img src="../design/icons/delete.png" style="margin-left:-5px;">

                            </button>
                        </td>
                    </tr>
                </table>
        </div>
    </div>

    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('calenderSettings');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
